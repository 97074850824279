import Css from "./style.module.scss";

import React, { useCallback, useContext } from "react";

import { IconInvalid } from "lib/icons";
import FormContext from "cntx/FormContext";

const FormField = (props) => {
  const { values, errors, disabled: formDisabled, onChange } = useContext(FormContext);

  const {
    icon,
    name,
    error,
    placeholder,
    multiline,
    disabled = formDisabled,
    component: Component = multiline ? "textarea" : "input",
    ...restProps
  } = props;

  const handleChange = useCallback((event) => {
    onChange({ name, value: event.target.value });
  }, [name, onChange]);

  return (
    <label className={Css.formField} disabled={disabled}>
      <Component
        {...restProps}
        disabled={disabled}
        name={name}
        value={values[name]}
        placeholder={placeholder}
        data-error={errors[name]}
        onChange={handleChange} />
      <div className={Css.iconLabel}>{icon}</div>
      {errors[name] && (<div className={Css.iconInvalid}><IconInvalid /></div>)}
    </label>
  );
};

export default FormField;
