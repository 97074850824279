const HTTPS_STATUS_OK = 200;

const HTTPS_STATUS_UNAUTHORIZED = 401;

const HTTPS_STATUS_FORBIDDEN = 403;

const HTTPS_STATUS_NOT_FOUND = 404;

const HTTPS_STATUS_TOO_MANY_REQUESTS = 429;

const REQUEST_METHOD_GET = "GET";

const REQUEST_METHOD_POST = "POST";

const REQUEST_METHOD_PUT = "PUT";

const REQUEST_METHOD_DELETE = "DELETE";

const DEFAULT_HEADERS = {
  Accept: "*/*",
  "Accept-Language": "en",
};

const JSON_HEADER = { "Content-Type": "application/json" };

const ERROR_UNAUTHORIZED = "ERROR_UNAUTHORIZED";

const ERROR_NOT_FOUND = "ERROR_NOT_FOUND";

const encode = (str) => encodeURIComponent(str);

const objectToQueryString = (params) =>
  Object.entries(params)
    .filter(([prop, val]) => prop && val)
    .map(
      ([prop, val]) => `${encode(prop)}${val === true ? "" : `=${encode(val)}`}`,
    )
    .join("&");

export const formData = (data) => {
  const fd = new FormData();

  Object.entries(data).forEach(([key, val]) => fd.append(key, val));

  return fd;
};

export const request = async({ method, path, params, payload, onError }) => {
  const url = `${path}${params ? `?${objectToQueryString(params)}` : ""}`;

  const formDataPayload = payload instanceof FormData;

  const options = {
    method,
    headers: {
      ...DEFAULT_HEADERS,
      ...(formDataPayload ? null : JSON_HEADER),
    },
    body: formDataPayload ? payload : JSON.stringify(payload),
  };

  try {
    const response = await window.fetch(url, options);

    switch (response.status) {
      case HTTPS_STATUS_UNAUTHORIZED:
      case HTTPS_STATUS_FORBIDDEN:
        throw ERROR_UNAUTHORIZED;
      case HTTPS_STATUS_NOT_FOUND:
        throw ERROR_NOT_FOUND;
      case HTTPS_STATUS_OK:
        return response.json();
      default: {
        const responseJson = await response.json();

        if (onError) {
          return await onError(responseJson);
        }
        throw {
          data: responseJson,
          status: response.status,
        };
      }
    }
  } catch (error) {
    throw error;
  }
};

export const make = ({ baseUrl = "", ...options }) => {
  const fn = (dir = "") => make({ baseUrl: `${baseUrl}${dir}`, ...options });

  fn.get = (path = "", params) => request({ method: REQUEST_METHOD_GET, path: `${baseUrl}${path}`, params, ...options });
  fn.post = (path = "", payload) => request({ method: REQUEST_METHOD_POST, path: `${baseUrl}${path}`, payload, ...options });
  fn.put = (path = "", payload) => request({ method: REQUEST_METHOD_PUT, path: `${baseUrl}${path}`, payload, ...options });
  fn.delete = (path = "", payload) => request({ method: REQUEST_METHOD_DELETE, path: `${baseUrl}${path}`, payload, ...options });

  return fn;
};

export { HTTPS_STATUS_TOO_MANY_REQUESTS };
