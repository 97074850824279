import FormContext from "cntx/FormContext";
import React, { useCallback, useMemo, useState } from "react";

import { emptyObject } from "utils";

const Form = (props) => {
  const {
    children,
    initialValues = {},
    validator,
    onSubmit,
    ...restProps
  } = props;

  const [values, setValues] = useState(initialValues);

  const [errors, setErrors] = useState({});

  const [disabled, setDisabled] = useState(false);

  const handleChange = useCallback(({ name, value }) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: null }));
  }, []);

  const handleFormSubmit = useCallback(async(event) => {
    if (event) event.preventDefault();
    if (disabled) return;
    setDisabled(true);

    if (validator) {
      const validationErrors = await validator(values);

      setErrors(validationErrors);

      if (!emptyObject(validationErrors)) {
        setDisabled(false);

        return;
      }
    }

    const submitErrors = await onSubmit(values);

    if (submitErrors) {
      setErrors((prev) => ({ ...prev, ...submitErrors }));
    } else {
      setValues(initialValues);
    }

    setDisabled(false);
  }, [disabled, values, initialValues, validator, onSubmit]);

  const providedValue = useMemo(() => ({
    values,
    errors,
    disabled,
    onChange: handleChange,
    onSubmit: handleFormSubmit,
  }), [
    values,
    errors,
    disabled,
    handleChange,
    handleFormSubmit,
  ]);

  return (
    <FormContext.Provider value={providedValue}>
      <form {...restProps} disabled={disabled} onSubmit={handleFormSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export default Form;

export { default as FormField } from "./lib/FormField";

