import { API_URL } from "const";
import { make } from "./request";
import { storageValue } from "utils";

const api = make({ baseUrl: API_URL });

export const sendContactFormData = (payload) => {
  try {
    if (window.hj) {
      // eslint-disable-next-line no-undef
      hj("identify", null, payload);
    }
  } catch (exception) {
    // eslint-disable-next-line no-console
    console.log("window.hj exception", exception);
  }

  return api.post("/contact-us", {
    httpReferer: storageValue("referrer"),
    ...payload,
  });
};
