const PHONE_NUMBER_LENGTH_MAX = 15;

const PHONE_NUMBER_LENGTH_MIN = 9;

// eslint-disable-next-line max-len
const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PHONE_REGEXP = /^\d+$/;

const PHONE_NORMALIZE_REGEXP = /[ +\-()]/g;

const ERROR_REQUIRED_FIELD = "ERROR_REQUIRED_FIELD";

const ERROR_INCORRECT_EMAIL = "ERROR_INCORRECT_EMAIL";

const ERROR_INCORRECT_PHONE_NUMBER = "ERROR_INCORRECT_PHONE_NUMBER";

const ERROR_INCORRECT_PHONE_NUMBER_LENGTH_MIN = "ERROR_INCORRECT_PHONE_NUMBER_LENGTH_MIN";

const ERROR_INCORRECT_PHONE_NUMBER_LENGTH_MAX = "ERROR_INCORRECT_PHONE_NUMBER_LENGTH_MIN";

const validateField = (name, value, params) => {
  const { required, email, phone } = params;

  if (!value) {
    return required ? ERROR_REQUIRED_FIELD : false;
  }
  if (email && !EMAIL_REGEXP.test(value)) {
    return ERROR_INCORRECT_EMAIL;
  }
  if (phone) {
    const normalized = value.replace(PHONE_NORMALIZE_REGEXP, "");

    if (!PHONE_REGEXP.test(normalized)) {
      return ERROR_INCORRECT_PHONE_NUMBER;
    }
    if (normalized.length < PHONE_NUMBER_LENGTH_MIN) {
      return ERROR_INCORRECT_PHONE_NUMBER_LENGTH_MIN;
    }
    if (normalized.length > PHONE_NUMBER_LENGTH_MAX) {
      return ERROR_INCORRECT_PHONE_NUMBER_LENGTH_MAX;
    }
  }

  return false;
};

const validate = (model, constraints) => {
  const errors = {};

  Object.entries(constraints).forEach(([name, params]) => {
    const error = validateField(name, model[name], params);

    if (error) errors[name] = error;
  });

  return errors;
};

export default validate;
